export const practitionerSignUp = {
  userSignup: "<0>{{user}}</0> sign-up",
  Practitioner: "Practitioner",
  CreateAccountHeader: "Create a practitioner account",
  startPractitionerAccount: "Start a practitioner account",
  allAccountsAreFree: "All accounts are <1>100% free.</1>",
  dispensaryName:
    "The name of your practice, it will be visible to {{patientTermPlural}} and can be changed later.",
  matchHealthCareCredentials:
    "This helps us provide suitable products for you, and should match your healthcare credentials.",
  agreeToSValidation: "Please agree to ToS and Privacy Statement before continuing",
  healthProfessionValidation: "Health profession is required",
  TellUsAboutYourPractice:
    "Tell us a bit about your practice to get started. All accounts are <2>100% free.</2>",
  HowToCompleteYourAccount: "How would you like to complete your account, {{ firstName }}?",
  SelectSignUpMethod: "Select your preferred sign up method to finish creating your account.",
  Emerson: {
    CreateAccountHeader: "Create account",
    TellUsAboutYourPractice: "Tell us a bit about your business to get started.",
    HowToCompleteYourAccount: "Complete your signup, {{ firstName }}",
    SelectSignUpMethod:
      "Add your email and your preferred password to finish creating your account.",
    PractUSPOne:
      "Choose from over <strong>400</strong> of healthcare's best brands at wholesale prices.",
    PractUSPTwo: "Comprehensive catalog with advanced search & highest quality products.",
    PractUSPThree:
      "Offer supplements from <strong>Thorne, Metagenics, Ortho Molecular and more.</strong>",
  },
  NextSelectSignUpMethod: "Next: Select sign up method",
  disabledNextButtonTooltip:
    "Complete all form fields and accept the terms of service to continue.",
  LetsGetStarted: "Let's get started! All accounts are 100% <2>free</2>",
  peerReferral: {
    signUpTitle: "{{ referrerFirstName }} thinks you'll love Fullscript! We do too.",
    demoVideoModalTitle: "How Fullscript can help your practice",
    demoVideoLink: "https://www.youtube.com/embed/RH9goQv6qmE",
    demoVideoTitle: "",
  },
  PractUSPOne: "Total dispensing freedom with one platform.",
  PractUSPTwo:
    "Streamline your workflow with more EHR integrations than any other dispensing platform.",
  PractUSPThree:
    "Use supplements to grow your practice. Use our features to promote it. Do both with one platform.",
  PractUSPFour: "Automated patient adherence built right into your dispensing workflow.",
  PractUSPFive: "Look no further. We have the best product search out there.",
  SelectPractitioner: "Select a practitioner type",
  ImAPatient: "I'm a <1>patient</1> - Help me get started",
  ImAPatientMobile: "Take me to <1>patient</1> sign-up",
  ContinueWithEmail: "Continue with email",
  ComingFromWellevate: {
    fromWellevate: "Coming over from Wellevate?",
    lookingForwardToServingYou:
      "Welcome! We're looking forward to serving you and your patients. Have questions? <1>Book a demo</1>",
  },
  PracNameTooltip: "This is the name your patients will see.",
  DispensaryNameTooltip:
    "Your dispensary is where your patients will purchase supplements. You can use your practice name here. If you don't want to customize it, we'll use your first and last name.",
  DesignationSearchTooltip: "This helps us provide suitable products for you.",
  ComingEmersonEcologics: {
    complementEmersonEcologics:
      "Complement your Emerson Ecologics® wholesale practice by adding a virtual dispensing option with a Fullscript store.",
    allAccountsZeroPerMonth: "All accounts are $0/month for both practitioners and patients.",
    lookingForwardToServingYou:
      "We're looking forward to serving you and your patients. Have questions? Our world-class customer support team is here to help. <1>Book a demo</1>",
  },
  AnAccountWithThisEmail: "An account with this email exists. Please <1>sign in</1>.",
  signInRedirectCTA: "sign in",
  HealthcaresBestSupplement:
    "Write healthcare’s best supplement and wellness plans — free on Fullscript.",
  PracOnboarding: {
    headerText: "Create your practitioner account",
    TellUsAboutYourself:
      "Tell us a bit about yourself to get started. All accounts are <2>100% free.</2>",
    nextButtonText: "Continue",
    steps: {
      AccountCreation: "Account creation",
      SignUp: "Sign up",
      AccountGoals: "Account goals",
      Credentials: "Credentials",
      Confirmation: "Confirmation",
    },
    CreateAccount: "Create account",
    CreateYourAccount: "Create your account",
    Continue: "Continue",
    HowToSignIn: "Choose your sign up method",
    CreateYourLogin: "Create your credentials or sign up with a partner account.",
  },
} as const;
