import { ApolloLink } from "@apollo/client";

import { getAttributionParams } from "@shared/hooks/useUnauthUrlFields/useUnauthUrlFields";
export const getAddUTMLink = () => {
  return new ApolloLink((operation, forward) => {
    operation.extensions.sessionUTMs = getAttributionParams();

    return forward(operation);
  });
};
